import React from 'react';
import '../Style/footer.css';

export default function Footer() { 
    return (
        <footer>
            <div className='pe'>
                <span>Dev João Moreira - 2023</span>
            </div>        
        </footer>        
    )
}
