import React from 'react';

export default function PortugueseTitle() {
    return (
      <>
        <h1>
          Olá, meu nome é 
        <br/>
          João Moreira!</h1>
        <h2>
          Eu vou mostrar meus principais projetos como
        <br/>
          Desenvolvedor FullStack</h2>
      </>
    )
}
